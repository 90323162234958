import React, { useState } from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Slider from "react-slick"
import Fade from "react-reveal/Fade"
import { isAndroid, isMobile } from "react-device-detect"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import SEO from "../../components/core/seo"
import useWindowSize from "../../components/hooks/windowSize"

import linkBackgroundImage from "../../images/graphic-link-bg-exelon.png"

import mobileA from "../../images/work/exelon/work-exelon-0-mobile.jpg"
import mobileB from "../../images/work/exelon/work-exelon-1-mobile.jpg"
import mobileC from "../../images/work/exelon/work-exelon-2-mobile.jpg"
import mobileD from "../../images/work/exelon/work-exelon-3-mobile.jpg"

import socialOG from "../../images/social-opengraph-work-exelon.png"

import FixedBackground from "../../components/elements/image-background-fixed"
import Layout from "../../components/core/layout"
import PrimaryImageWE from "../../components/work/image-work-exelon"
import Contact from "../../components/contact"
import ShowcaseX from "../../components/elements/image-work-X-exelon"
import PrimaryButton from "../../components/button/primary-button"
import NextPrev from "../../components/button/next-prev-buttons"

// Components

const PrimarySpacer = styled.div`
  margin-top: 200px;
`

const HeaderSpacer = styled.div`
  height: 120px;
  ${media.lessThan("medium")`
        display: none;
    `}
`

const FlexSpacer = styled.div`
  flex-grow: 1;
  ${media.lessThan("medium")`
        display: none;
    `}
`

const SectionHeader = styled.div`
  font-family: "Nitti Bold";
  margin-top: 65px;
  margin-bottom: 35px;
  pointer-events: none;

  ${media.lessThan("medium")`
    font-size: 10px;
    margin-top: 15px;
    margin-bottom: 15px;
  `}
`

const SectionContent = styled.div`
  line-height: 1rem;

  ${media.lessThan("medium")`
      width: 300px;
      font-size: 13px;
      line-height:1.1rem;
  `}
`

const WorkClient = styled.div`
  font-family: "Roobert Bold";
  padding-top: 140px;
  font-size: 3.375rem;
  line-height: 2.9rem;
  white-space: nowrap;
  pointer-events: none;

  ${media.lessThan("medium")`
    font-size: 2.1rem;
    line-height: 2rem;
    margin-top: 90px;
    padding-top: 0px;
    width: 200px;
  `}
`

const WorkSubhead = styled.div`
  font-family: "Roobert";
  font-size: 20px;
  margin-top: 20px;
  line-height: 1.1;

  ${media.lessThan("medium")`
        width: 250px;
        margin-top: 10px;
        margin-bottom: 30px;
        font-size: 14px;
    `}
`

const SectionHorizontalRule = styled.div`
  height: 1px;
  width: 80px;
  margin-top: 80px;
  margin-bottom: 80px;
  background-color: #535663;
  margin-left: auto;
  margin-right: auto;

  ${media.lessThan("medium")`
        margin-top: 0px;
    `}
`

// Main Section

const IndexContainer = styled.div`
  overflow-x: hidden;
  width: 100vw;
  height: 100%;
`

const MainContainer = styled.div`
  display: flex;
  align-items: flex-end;

  ${media.lessThan("medium")`
        flex-direction: column;
        align-items: flex-start;
    `}
`

const MainLeft = styled.div`
  flex-grow: 1;
  max-width: 50%;
  padding-right: 80px;

  ${media.lessThan("medium")`
        padding-right: 0px;
    `}
`

const VerticalRule = styled.div`
  height: 400px;
  width: 1px;
  bottom: 0px;
  background-color: #535663;

  ${media.lessThan("medium")`
    width: 4px;
    display: none;
  `}
`

const HorizontalRule = styled.div`
  display: none;

  ${media.lessThan("medium")`
    display: block;
    width: 4px;
    height: 1px;
    width: 100%;
    margin-top: 80px;
    background-color: #535663;
  `}
`

const MainRight = styled.div`
  display: flex;
  padding-left: 80px;
  padding-top: 20px;
  flex-grow: 1;
  max-width: 50%;
  flex-direction: column;
  align-items: baseline;

  ${media.lessThan("medium")`
        padding-left: 0px;
        padding-top: 0px;
        flex-grow: 0;
        max-width: 90%;
        margin-bottom: 80px;
    `}
`

const SectionImpact = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
`

const ImpactContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: stretch;
`

const ImpactTotal = styled.div`
  font-family: "Roobert Bold";
  font-size: 40px;

  ${media.lessThan("1000px")`
        font-size: 30px;
    `}

  ${media.lessThan("medium")`
        font-size: 26px;
    `}
`

const ImpactDescription = styled.div`
  font-family: "Nitti Bold";
  font-size: 13px;
  line-height: 0.8rem;
  margin-top: 12px;
  max-width: 120px;

  ${media.lessThan("1000px")`
        font-size: 11px;
    `}

  ${media.lessThan("medium")`
        font-family: "Nitti Normal";
        font-size: 11px;
        max-width: 80px;
    `}
`

// Middle Section

const MiddleContainer = styled.div`
  display: flex;
  justify-content: space-evenly;

  ${media.lessThan("medium")`
        flex-direction: column;
        justify-content: stretch;
    `}
`

const PhaseDisciplineContainer = styled.ul`
  justify-self: baseline;
`

const PhaseDisciplines = styled.li`
  line-height: 0.5;
  white-space: nowrap;

  ${media.lessThan("1220px")`
    font-size: 12px;
  `}

  ${media.lessThan("medium")`
    font-size: 11px;
  `}
`

// Showcase

const ShowcaseContainer = styled.div`
  display: flex;
  font-family: "Roobert Bold";
  text-align: center;
  font-size: 40px;
  height: 80vh;

  align-items: center;
  justify-content: center;
  margin-top: 80px;

  /* Firefox */
  @-moz-document url-prefix() {
    & {
      margin-top: 200px;
      margin-bottom: 100px;
    }
  }
  /* Microsoft IE10 and above */
  @media all and (-ms-high-contrast: none) {
    & {
      margin-top: 200px;
      margin-bottom: 100px;
    }
  }
  /* Microsoft Edge */
  @supports (-ms-ime-align: auto) {
    & {
      margin-top: 200px;
      margin-bottom: 100px;
    }
  }

  ${media.lessThan("1000px")`
        height: 60vh;
        margin-top: 200px;
    `}

  ${media.lessThan("medium")`
        margin-top: -40px;
        flex-direction: column;
        margin-bottom: 80px;
        height: 60vh;
    `}
    min-height: 450px;
`

const ShowcaseSliderMobile = styled(Slider)`
  ${media.greaterThan("medium")`
        width: 70vw;
        max-width: 950px;
        height: 800px;
        border-radius: 16px;
        margin-left: 10vw;
        margin-top: 140px;
    `}

  ${media.lessThan("medium")`
        margin-left: -10px;
        margin-top: 40px;
        width: 200px;
        height: 410px;
        overflow: hidden;
        mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
        -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
        border-radius: 10px;
    `}
`

const MobileSlide = styled.div`
  ${media.greaterThan("medium")`
        display: flex;
        justify-items: center;
        align-items: center;
        padding-top: 60px;
        padding-bottom: 40px;
        padding-left: 30px;
        padding-right: 30px;
        transition: .25s all ease-out;
    `}
`

const MobileInner = styled.div`
  ${media.greaterThan("medium")`
        margin-left: 10px;
        margin-right: 10px;
        overflow: hidden;
        mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
        -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
        border-radius: 10px;
        height: 90%;
    `}
`

const MobileIMG = styled.img`
  margin-bottom: 0px;
`

const ShowcaseSliderShadow = styled.div`
  width: 200px;
  height: 437px;
  border-radius: 16px;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.2);
  position: absolute;
  margin-left: 380px;
  margin-top: 60px;

  ${media.greaterThan("medium")`
        display: none;
    `}

  ${media.lessThan("medium")`
        border-radius: 10px;
        height: 383px;
        margin-left: -5px;
        margin-top: 36px;
    `}
`

const ProjectCounter = styled.div`
  position: absolute;
  text-align: right;
  font-family: "Roobert SemiBold";
  font-size: 2rem;
  line-height: 1.8rem;
  margin-top: -325px;
  margin-bottom: 2.2rem;
  flex-grow: 1;
  right: 165px;
  pointer-events: none;

  /* Firefox */
  @-moz-document url-prefix() {
    & {
      margin-right: -100px;
      margin-top: -410px;
    }
  }

  /* Microsoft IE10 and above */
  @media all and (-ms-high-contrast: none) {
    & {
      margin-right: -100px;
      margin-top: -410px;
    }
  }

  /* Microsoft Edge */
  @supports (-ms-ime-align: auto) {
    & {
      margin-right: -100px;
      margin-top: -410px;
    }
  }

  ${media.lessThan("medium")`
    margin-top: ${isAndroid ? -134 : -130}px
    margin-right: 30px;
    font-size: 18px;
    line-height: 1rem;
    margin-top: -80px;
    right: 10px;
  `}
`

// More Work

const MoreClient = styled.div`
  margin-bottom: -20px;
  margin-left: 18px;
  margin-right: 18px;

  ${media.lessThan("medium")`
        margin-bottom: -8px;
    `}
`

const MoreWork = styled.div`
  display: flex;
  justify-content: stretch;
  margin-top: 80px;
  margin-bottom: -40px;
`

const PreviousWork = styled.div`
  display: flex;
  align-items: center;
  font-family: "Roobert SemiBold";
  font-size: 32px;

  ${media.lessThan("medium")`
        font-size: 16px;
    `}
`

const WorkSpacer = styled.div`
  flex-grow: 1;
`

const NextWork = styled.div`
  display: flex;
  align-items: center;
  font-family: "Roobert SemiBold";
  font-size: 32px;

  ${media.lessThan("medium")`
        font-size: 16px;
    `}
`

const ButtonArrow = styled.div`
  font-size: 80px;
  font-family: "Roobert SemiBold";
  line-height: 1.1;
  color: #b8b8b8;

  &:hover {
    color: #b8b8b8;
  }

  ${media.lessThan("medium")`
        font-size: 40px;
    `}
`

const CaseStudy = () => {
  let mobileSlider
  const [currentSlide, setCurrentSlide] = useState(0)
  const windowSize = useWindowSize()
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
  }

  settings.slidesToShow = windowSize.width <= 550 || isMobile ? 1 : 3

  const next = () => {
    mobileSlider.slickNext()
  }

  const previous = () => {
    mobileSlider.slickPrev()
  }

  return (
    <IndexContainer>
      <FixedBackground />
      <PrimaryImageWE />
      <Layout activeSection="work">
        <SEO
          title="Futurehaus: Exelon EcoCRED Case Study"
          description="Rewarding consumers for tracking and minimizing their carbon footprints"
          url="https://futurehaus.studio/work/exelon"
          opengraph={socialOG}
        />
        <MainContainer>
          <MainLeft>
            <PrimarySpacer />
            <WorkClient>Exelon</WorkClient>
            <WorkSubhead>
              Rewarding consumers for tracking and minimizing their carbon
              footprints
            </WorkSubhead>
            <SectionHeader>Where it started</SectionHeader>
            <SectionContent>
              When Exelon wanted to create a fun, interactive tool to encourage
              consumers to reduce their carbon footprints, the company chose
              Futurehaus.
            </SectionContent>
            <HeaderSpacer />
          </MainLeft>
          <VerticalRule />
          <HorizontalRule />
          <MainRight>
            <FlexSpacer />
            <SectionHeader>Results</SectionHeader>
            <PhaseDisciplineContainer>
              <PhaseDisciplines className="discipline-bullet exelonBrand">
                New, profitable mobile app, EcoCRED, <br />
                <div style={{ marginTop: "10px" }}>
                  that incentivizes sustainable energy use
                </div>
              </PhaseDisciplines>
              <PhaseDisciplines className="discipline-bullet exelonBrand">
                Internal and external pilot launches
              </PhaseDisciplines>
              <PhaseDisciplines className="discipline-bullet exelonBrand">
                Product positioning strategy
              </PhaseDisciplines>
              <PhaseDisciplines className="discipline-bullet exelonBrand">
                App redesign based on user feedback
              </PhaseDisciplines>
              <PhaseDisciplines className="discipline-bullet exelonBrand">
                More than 20,000 daily active app use
              </PhaseDisciplines>
            </PhaseDisciplineContainer>
            <SectionHeader>Metrics at a glance</SectionHeader>
            <ImpactContainer>
              <SectionImpact>
                <div>
                  <ImpactTotal>25K</ImpactTotal>
                  <ImpactDescription>
                    Daily active <br />
                    app use
                  </ImpactDescription>
                </div>
                <div>
                  <ImpactTotal>600K</ImpactTotal>
                  <ImpactDescription>
                    Habits <br />
                    completed
                  </ImpactDescription>
                </div>
                <div>
                  <ImpactTotal>2.5MM</ImpactTotal>
                  <ImpactDescription>
                    CO2 <br />
                    reduced
                  </ImpactDescription>
                </div>
              </SectionImpact>
            </ImpactContainer>
          </MainRight>
        </MainContainer>
        <ShowcaseContainer>
          <ShowcaseX />
          <NextPrev
            color="#349b4a"
            mobileScreensOnly
            previous={previous}
            next={next}
          />
          <ProjectCounter>
            <div>0{currentSlide === 0 ? 4 : currentSlide}</div>
            <div>/04</div>
          </ProjectCounter>
          <ShowcaseSliderShadow />
          <ShowcaseSliderMobile
            ref={c => (mobileSlider = c)}
            {...settings}
            beforeChange={(oldIndex, newIndex) => {
              setCurrentSlide(newIndex)
            }}
          >
            <MobileSlide
              key={1}
              className={currentSlide === 0 ? "mobileCenter" : ""}
            >
              <MobileInner>
                <MobileIMG
                  alt="The EcoCRED welcome screen for a logged in user, showing their carbon footprint."
                  src={mobileA}
                />
              </MobileInner>
            </MobileSlide>
            <MobileSlide
              key={2}
              className={currentSlide === 1 ? "mobileCenter" : ""}
            >
              <MobileInner>
                <MobileIMG
                  alt="Featured habits screen where users can see and complete habits to reduce their footprint."
                  src={mobileB}
                />
              </MobileInner>
            </MobileSlide>
            <MobileSlide
              key={3}
              className={currentSlide === 2 ? "mobileCenter" : ""}
            >
              <MobileInner>
                <MobileIMG
                  alt="Community screen where users can see how they compare to others."
                  src={mobileC}
                />
              </MobileInner>
            </MobileSlide>
            <MobileSlide
              key={4}
              className={currentSlide === 3 ? "mobileCenter" : ""}
            >
              <MobileInner>
                <MobileIMG
                  alt="Impact breakdown screen demonstrating what their CO2 reductions mean. For example, 9.6 lb CO2 is equal to the impact of 2.1 seedlings planted."
                  src={mobileD}
                />
              </MobileInner>
            </MobileSlide>
          </ShowcaseSliderMobile>
        </ShowcaseContainer>
        <Fade bottom cascade duration={1250} distance="25px">
          <MiddleContainer>
            <MainLeft style={{ minHeight: 0 }}>
              <SectionHeader>What we did</SectionHeader>
              <SectionContent>
                Working in partnership with their in-house team, we facilitated
                design workshops to identify which possible features people
                wanted. We found that language was important if users were to
                understand their carbon footprint. People appreciated specific
                comparisons to which they could relate, such as saving four
                trees today. And we introduced the idea of streaks, increased
                rewards over time to help boost long-term engagement.
                <br />
                <br />
                We built, tested, and iterated on prototypes, critical
                precursors for the strategic development of a new app. The
                result: EcoCRED, a gamification and storytelling app that allows
                individuals and organizations to measure, track, and manage
                their carbon footprints, and earn credits and bonuses as they
                do.
                <br />
                <br />
                We worked with Exelon to launch a consumer and business facing
                version on the App Store. During the project, we trained
                Exelon’s in-house team and engineers, so they could continue to
                effectively manage the product, learn from their audience,
                measure how well the app was doing, and adapt quickly thanks to
                a solid foundation. We helped Exelon consider various business
                models for the new tool, which now boasts more than 25,000 daily
                active users who are doing their part for planet Earth.
              </SectionContent>
              <PrimaryButton
                blank
                topSpacing
                backgroundImage={linkBackgroundImage}
                link="https://www.ecocred.io"
              >
                Download the app
              </PrimaryButton>
            </MainLeft>
            <HorizontalRule />
            <MainRight>
              <SectionHeader>Services</SectionHeader>
              <PhaseDisciplineContainer>
                <PhaseDisciplines className="discipline-bullet exelonBrand">
                  Design and technology strategy
                </PhaseDisciplines>
                <PhaseDisciplines className="discipline-bullet exelonBrand">
                  Research, including stakeholder
                  <br />
                  <div style={{ marginTop: "10px" }}>and expert interviews</div>
                </PhaseDisciplines>
                <PhaseDisciplines className="discipline-bullet exelonBrand">
                  Problem definition and feature prioritization
                </PhaseDisciplines>
                <PhaseDisciplines className="discipline-bullet exelonBrand">
                  Prototyping
                </PhaseDisciplines>
                <PhaseDisciplines className="discipline-bullet exelonBrand">
                  User experience and visual design
                </PhaseDisciplines>
                <PhaseDisciplines className="discipline-bullet exelonBrand">
                  Agile product development
                </PhaseDisciplines>
                <PhaseDisciplines className="discipline-bullet exelonBrand">
                  Launch and product support
                </PhaseDisciplines>
                <PhaseDisciplines className="discipline-bullet exelonBrand">
                  Internal product team training
                </PhaseDisciplines>
                <PhaseDisciplines className="discipline-bullet exelonBrand">
                  Sustainable business model development
                </PhaseDisciplines>
              </PhaseDisciplineContainer>
              <SectionHeader>In collaboration with</SectionHeader>
              <SectionContent>
                <a
                  href="https://reachcreative.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Reach Creative
                </a>
              </SectionContent>
            </MainRight>
          </MiddleContainer>
        </Fade>
        <SectionHorizontalRule />
        <Contact small backgroundImage={linkBackgroundImage} />
        <MoreWork>
          <Fade left distance="20px">
            <AniLink
              to="/work/yearup"
              cover
              direction="left"
              bg="#222533"
              className="caseLink"
            >
              <PreviousWork>
                <ButtonArrow>←</ButtonArrow>
                <MoreClient>Year Up</MoreClient>
              </PreviousWork>
            </AniLink>
          </Fade>
          <WorkSpacer />
          <Fade right distance="20px">
            <AniLink
              to="/work/jugl"
              cover
              direction="right"
              bg="#222533"
              className="caseLink"
            >
              <NextWork>
                <MoreClient>Jugl</MoreClient>
                <ButtonArrow>→</ButtonArrow>
              </NextWork>
            </AniLink>
          </Fade>
        </MoreWork>
      </Layout>
    </IndexContainer>
  )
}

export default CaseStudy
